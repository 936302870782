import styled from 'styled-components';

export const SettingsHeader = styled.h3`
  width: 100%;
  text-align: center;
`;

export const SettingsItem = styled.div`
  width: 100%;
  text-align: center;
`;
